import { Box, Center, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { CtaFragment } from '@src/generated/graphql';
import SectionHeader from '@components/Shared/SectionHeader';
import getDocumentUrl from '@helpers/getDocumentUrl';
import FadeInWhenVisible from '@components/Shared/FadeInWhenVisible/FadeInWhenVisible';
import { NavigationButton } from '@components/Shared/NavigationButton';

const CTASection: React.FC<CtaFragment> = ({
  title,
  subtitle,
  backgroundImage,
  backgroundImageMobile,
  buttonText,
  link,
}) => {
  const [normalText, boldText] = title?.split(' ') ?? [];
  return (
    <FadeInWhenVisible>
      <Center
        as="section"
        backgroundImage={[
          backgroundImageMobile?.asset?.url ?? '',
          null,
          backgroundImage?.asset?.url ?? '',
        ]}
        px="20px"
      >
        <Box py={93}>
          <Flex
            backgroundColor="white"
            p={['20px 55px 30px', null, '115px 55px']}
            direction="column"
            align="center"
          >
            <SectionHeader normalText={normalText} boldText={boldText} />
            <Text
              color="grey.300"
              fontSize="lg"
              fontFamily="montserrat"
              mt="11px"
              mb="29px"
              textAlign="center"
            >
              {subtitle}
            </Text>
            <NavigationButton href={getDocumentUrl(link)}>
              {buttonText}
            </NavigationButton>
          </Flex>
        </Box>
      </Center>
    </FadeInWhenVisible>
  );
};

export default CTASection;
