import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  display: flex;
`;

export const SocialLink = styled.a`
  color: #a8b3b9;
  font-size: 24px !important;
  padding: 5px 10px;
  transition: all 0.5s ease;
  display: flex;
  svg {
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const SocialWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
  display: inline-block;
  padding: 4px;
  transition: all 0.5s ease;
  max-height: 44px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    ${SocialLink} {
      svg {
        path {
          fill: ${({ theme }) => theme.colors.secondary};
        }
      }
    }
  }
`;
