import {
  faFacebookF,
  faInstagram,
  faPinterest,
} from '@fortawesome/free-brands-svg-icons';
import { ISocialLink } from '../../Homepage/Header/interfaces';

const socialLinks: ISocialLink[] = [
  {
    link: 'https://www.facebook.com/ofrigpaz',
    icon: faFacebookF,
  },
  {
    link: 'https://www.instagram.com/ofri.paz/',
    icon: faInstagram,
  },
  {
    link: 'https://www.pinterest.com/ofri_paz/',
    icon: faPinterest,
  },
];

export default socialLinks;
