import React from 'react';
import { Heading, Text } from '@chakra-ui/react';
import { ISectionHeaderProps } from './interfaces';
import { colorsGrey } from '@src/theme/chakraTheme/colors';

const SectionHeader: React.FC<ISectionHeaderProps> = ({
  boldText,
  normalText,
}) => {
  return (
    <Heading
      as="h1"
      py="10px"
      fontSize="2em"
      fontWeight="bold"
      textAlign={['center', null, 'left']}
    >
      <Text
        as="span"
        fontFamily="montserrat"
        color={colorsGrey[300]}
        fontWeight="light"
      >
        {normalText}
      </Text>
      <Text as="span" fontFamily="montserrat" fontWeight="bold" color="primary">
        {' '}
        {boldText}
      </Text>
    </Heading>
  );
};

export default SectionHeader;
