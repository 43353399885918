import { createContext, useContext } from 'react';
import { AllAboutMeQuery } from '@src/generated/graphql';

const AboutMeContext = createContext<AllAboutMeQuery>({
  allHomepage: [],
});

export const AboutMeProvider = AboutMeContext.Provider;

export const useAboutMe = (): AllAboutMeQuery => useContext(AboutMeContext);

export default AboutMeContext;
