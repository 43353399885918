import { createContext, useContext } from 'react';
import { IPostsByCategory } from '@src/pageHelpers/home/getPostsByCategory';

const MyWorkContext = createContext<IPostsByCategory>({});

export const MyWorkProvider = MyWorkContext.Provider;

export const useMyWork = (): IPostsByCategory => useContext(MyWorkContext);

export default MyWorkContext;
