import { CtaFragment } from '@src/generated/graphql';

const getDocumentUrl = (link: CtaFragment['link']) => {
  if (!link) return '/';
  const { __typename, slug } = link;
  switch (__typename) {
    case 'Gallery':
    case 'MyProjects':
      return `/projects/${slug?.current}`;
    case 'Post':
      return `/blog/${slug?.current}`;
    case 'Services':
      return `/services/${slug?.current}`;

    default:
      return '/';
  }
};

export default getDocumentUrl;
