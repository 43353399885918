import React from 'react';
import Image, { ImageProps } from 'next/legacy/image';
import { SanityCustomImageFragment } from '@src/generated/graphql';
import { urlFor } from '@src/lib/sanity/sanity.client';

type NewImageProps = Partial<ImageProps>;

const SanityImage: React.FC<
  NewImageProps & {
    sanityImage: Maybe<SanityCustomImageFragment>;
    responsive?: boolean;
    children?: React.ReactNode;
  }
> = ({ sanityImage, ...props }) => {
  return (
    <Image
      src={urlFor(sanityImage).auto('format').url() ?? ''}
      placeholder="blur"
      blurDataURL={sanityImage?.asset?.metadata?.lqip ?? ''}
      width={
        props.layout === 'fill'
          ? undefined
          : sanityImage?.asset?.metadata?.dimensions?.width ?? 200
      }
      height={
        props.layout === 'fill'
          ? undefined
          : sanityImage?.asset?.metadata?.dimensions?.height ?? 200
      }
      alt={sanityImage?.alt ?? 'default alt'}
      style={{ transition: 'all 0.3s ease-in-out' }}
      {...props}
    />
  );
};

export default SanityImage;
