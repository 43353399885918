import React from 'react';
import { Fade, useMediaQuery } from '@chakra-ui/react';
import { StyledHeader } from './styles';
import HeaderTitle from './HeaderTitle';
import { IHeaderImages } from './interfaces';
import ShowIf from '@components/Shared/ShowIf';
import SanityImage from '@components/Shared/SanityImage';

const Header: React.FC<IHeaderImages> = ({ desktop, mobile }) => {
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  return (
    <>
      <StyledHeader suppressHydrationWarning={true}>
        <>
          <ShowIf device="mobile">
            <Fade in>
              <SanityImage
                sanityImage={mobile}
                width={764}
                height={970}
                loading="eager"
                layout="responsive"
              />
            </Fade>
          </ShowIf>

          <ShowIf device="desktop">
            <Fade in>
              <SanityImage
                sanityImage={desktop}
                priority
                loading="eager"
                layout="responsive"
              />
            </Fade>
            {isMobile || <HeaderTitle />}
          </ShowIf>
        </>
      </StyledHeader>
      {isMobile && <HeaderTitle />}
    </>
  );
};

export default Header;
