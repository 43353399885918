import React from 'react';
import { NextSeo } from 'next-seo';
import { ISEOProps } from './interfaces';

const SEO: React.FC<ISEOProps> = ({ title, description, image, url }) => {
  return (
    <NextSeo
      title={title ?? 'Ohsofree'}
      description={description ?? ''}
      openGraph={{
        url,
        title: title ?? 'Ohsofree',
        description: description ?? '',
        locale: 'en-US',
        type: 'website',
        images: [{ url: image ?? '' }],
        site_name: 'Ohsofree',
      }}
      twitter={{
        site: '@Ohsofree',
        cardType: 'summary_large_image',
      }}
    />
  );
};

export default SEO;
