import { useState, useCallback } from 'react';
import constate from 'constate';

const useHeaderLoaded = ({ isHeaderLoaded = false }) => {
  const [isLoaded, setIsLoaded] = useState(isHeaderLoaded);
  const toggle = useCallback(
    () => setIsLoaded((prevIsLoaded) => !prevIsLoaded),
    []
  );
  return { isLoaded, toggle };
};

export const [HeaderLoadedProvider, useIsHeaderLoaded, useToggleHeaderLoaded] =
  constate(
    useHeaderLoaded,
    (value) => value.isLoaded,
    (value) => value.toggle
  );
