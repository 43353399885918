import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Profession } from './HeaderTitleStyled';
import { IHeaderTitleProps } from './interfaces';
import Social from '@components/Shared/Social';
import socialLinks from '@components/Shared/Social/socialLinks';
import Logo from '@src/assets/Logo';

const HeaderTitle: React.FC<IHeaderTitleProps> = () => {
  return (
    <Flex
      direction="column"
      align={['center', null, 'flex-start']}
      pos={{ md: 'absolute' }}
      left="56%"
      top={['21%']}
      margin="0 auto"
    >
      <Box mt={4}>
        <Flex align="center" justify={['center', null, 'flex-start']}>
          <Logo width={250} height={80} />
        </Flex>
        <Profession>A designer&apos;s point of view</Profession>
      </Box>
      <Social socialLinks={socialLinks} mt="25px" />
    </Flex>
  );
};

export default HeaderTitle;
