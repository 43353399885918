import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import BContainer from 'react-bootstrap/Container';

export const TitleWrapper = styled(Col)`
  margin-left: 105px;
  margin-bottom: 25px;
  margin-top: 100px;
  ${({ theme }) => theme.BSExtraLarge`
    margin-left: 0;
  `};
`;

export const MyName = styled.div`
  span {
    &:first-child {
      font-family: ${({ theme }) => theme.fonts.montserrat};
      font-weight: 300;
      color: #868686;
      font-size: 2.9rem;
      ${({ theme }) => theme.BSExtraLarge`
        font-size: 2.5rem;
      `};
    }
    &:last-child {
      font-family: ${({ theme }) => theme.fonts.dancingScript.bold};
      color: ${({ theme }) => theme.colors.primary};
      font-size: 3.4rem;
      ${({ theme }) => theme.BSExtraLarge`
        font-size: 2.9rem;
      `};
    }
  }
  ${({ theme }) => theme.BreakpointMobile`
    font-size: 30px;
  `}
`;

export const Profession = styled.div`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-weight: 300;
  color: #868686;
  font-size: 1.5rem;
  ${({ theme }) => theme.BSExtraLarge`
    font-size: 1.25rem;
  `};
`;

export const Container = styled(BContainer)`
  position: absolute;
  top: 0;
  right: 0;
  max-width: none;
  padding-top: 70px;
  ${({ theme }) => theme.BSLarge`
    padding-top: 45px;
  `};
  ${({ theme }) => theme.BSMedium`
    padding-top: 0;
    position: static;
  `};
`;
