import { useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import { MotionDiv } from './FadeInWhenVisibleStyled';
import useOnScreen from '@src/hooks/useOnScreen';

const FadeInWhenVisible: FCWithChildren = ({ children }) => {
  const { isIntersecting, ref } = useOnScreen();
  const controls = useAnimation();

  useEffect(() => {
    if (isIntersecting) {
      controls.start('visible');
    }
  }, [controls, isIntersecting]);

  return (
    <MotionDiv
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 1 }}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: '30px' },
      }}
    >
      {children}
    </MotionDiv>
  );
};

export default FadeInWhenVisible;
