import styled from 'styled-components';

export const HEADER_IMAGE_ASPECT_RATIO = 55.08;
export const HEADER_IMAGE_ASPECT_RATIO_MOBILE = 125;

export const FadeIn = styled.div<{ show: boolean }>`
  transition: all 1s ease;
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

export const StyledHeader = styled.header`
  position: relative;
`;

export const Resume = styled.div`
  margin-top: 4px;
`;

export const ShowIfDesktop = styled.div`
  display: block;
  ${({ theme }) => theme.BreakpointMobile`
    display: none;
  `};
`;
export const ShowIfMobile = styled.div`
  display: none;
  ${({ theme }) => theme.BreakpointMobile`
    display: block;
  `};
`;
