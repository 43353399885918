import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { useCookies } from 'react-cookie';

const useHotjar = () => {
  const [cookies] = useCookies(['CookieConsent']);
  const consent = cookies.CookieConsent;

  const initializeHotjar = () => {
    if (process.env.NODE_ENV === 'production') {
      hotjar.initialize(
        parseInt(process.env.NEXT_PUBLIC_HOTJAR_ID ?? '', 10),
        parseInt(process.env.NEXT_PUBLIC_HOTJAR_VERSION ?? '', 10)
      );
    }
  };
  useEffect(() => {
    if (consent === 'true') {
      initializeHotjar();
    }
  }, [consent]);
  return initializeHotjar;
};
export default useHotjar;
