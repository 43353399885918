import React from 'react';
import { GetStaticProps } from 'next';
import { Flex } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import sdk from '@src/lib/sdk';
import Navigation from '@components/Shared/Navigation/Navigation';
import Header from '@components/Homepage/Header/Header';
import { MyWorkProvider } from '@components/Homepage/MyWork/MyWorkContext';
import { AboutMeProvider } from '@components/Homepage/AboutMe/AboutMeContext';
import { HeaderLoadedProvider } from '@src/contexts/headerLoaded';
import { AllAboutMeQuery, CtaFragment } from '@src/generated/graphql';
import getPostsByCategory, {
  IPostsByCategory,
} from '@src/pageHelpers/home/getPostsByCategory';
import { IHeaderImages } from '@components/Homepage/Header/interfaces';
import SEO from '@components/SEO';
import { MAIN_URL } from '@src/commons/constants';
import { MAIN_PAGE_DESC } from '@src/commons/seoConstants';
import CookieConsentWrapper from '@components/Shared/CookieConsentWrapper/CookieConsentWrapper';
import CTASection from '@components/Homepage/CTASection/CTASection';

const DynamicAboutMe = dynamic(() => import('@components/Homepage/AboutMe'));
const DynamicMyWork = dynamic(() => import('@components/Homepage/MyWork'));
const DynamicContactMe = dynamic(
  () => import('@components/Homepage/ContactMe')
);

export interface InitData {
  aboutMeData: AllAboutMeQuery;
  postsByCategory: IPostsByCategory;
  headerImages: IHeaderImages;
  topCta: CtaFragment;
  bottomCta: CtaFragment;
}

export const getStaticProps: GetStaticProps<InitData> = async () => {
  const aboutMeData = await sdk.allAboutMe();
  const allPosts = await sdk.allPosts();
  const homePage = await (await sdk.allHomepage()).allHomepage[0];
  const postsByCategory = getPostsByCategory(
    allPosts,
    homePage.categoryOrder,
    homePage.featuredPosts
  );
  return {
    props: {
      aboutMeData,
      postsByCategory,
      topCta: homePage.topCta ?? {},
      bottomCta: homePage.bottomCta ?? {},
      headerImages: {
        desktop: homePage.desktopImage,
        mobile: homePage.mobileImage,
      },
    },
  };
};

const IndexPage: React.FC<InitData> = ({
  aboutMeData,
  postsByCategory,
  headerImages,
  bottomCta,
  topCta,
}) => {
  return (
    <CookieConsentWrapper>
      <Flex direction="column">
        <SEO
          title="Ohsofree - A designer's point of view"
          description={MAIN_PAGE_DESC}
          image={headerImages.desktop?.asset?.url ?? ''}
          url={MAIN_URL}
        />
        <Navigation showLogo={false} />
        <HeaderLoadedProvider>
          <Header {...headerImages} />
          <AboutMeProvider value={aboutMeData}>
            <DynamicAboutMe />
          </AboutMeProvider>
          <CTASection {...topCta} />
          <MyWorkProvider value={postsByCategory}>
            <DynamicMyWork />
          </MyWorkProvider>
          <CTASection {...bottomCta} />
          <DynamicContactMe />
        </HeaderLoadedProvider>
      </Flex>
    </CookieConsentWrapper>
  );
};

export default IndexPage;
