export const MAIN_PAGE_DESC = `I'm an Interior designer, content creator and blogger. Oh so free is my virtual home and where I write about design, motherhood and life on relocation.`;

export const MAIN_BLOG_PAGE_TITLE = `Oh so free blog by Ofri Paz`;
export const MAIN_BLOG_PAGE_DESC = `A designer's point of view on interiors, shopping, motherhood. relocation and Berlin life.`;

export const ABOUT_ME_PAGE_TITLE = `About Ofri Paz`;
export const ABOUT_ME_PAGE_DESC = `Interior designer, interior stylist, content creator, writer and blogger, married to Rafael, mother to baby Libby, toddler Robin and doggy Louie.`;

export const CONTACT_ME_PAGE_TITLE = `Contact Ofri Paz`;
export const CONTACT_ME_PAGE_DESC = `Write, text or call me for any suggestion, project or question`;
