import React from 'react';
import Link from 'next/link';
import { Button } from '@chakra-ui/react';
import { INavigationButtonProps } from './interfaces';

const NavigationButton: FCWithChildren<INavigationButtonProps> = ({
  href,
  children,
  ...rest
}) => (
  <Link href={href} passHref>
    <Button
      color="white"
      fontFamily="montserrat"
      fontWeight="semibold"
      outline="none"
      py="8px"
      px="16px"
      bgColor="primary"
      borderRadius={0}
      borderWidth="1px"
      borderColor="primary"
      borderStyle="solid"
      transition="all 0.3s ease"
      _hover={{
        color: 'primary',
        borderColor: 'primary',
        bgColor: 'white',
      }}
      {...rest}
    >
      {children}
    </Button>
  </Link>
);

export default NavigationButton;
