import React from 'react';
import CookieConsent from 'react-cookie-consent';
import theme from '@src/theme';
import useHotjar from '@src/hooks/useHotjar';

const CookieConsentWrapper: FCWithChildren = ({ children }) => {
  const initializeHotjar = useHotjar();
  return (
    <>
      {children}
      <CookieConsent
        buttonStyle={{
          backgroundColor: theme.colors.primary,
          color: theme.colors.white,
          fontFamily: theme.fonts.montserrat,
          fontWeight: 700,
        }}
        onAccept={initializeHotjar}
      >
        This website uses cookies to collect information and provide you with
        the best experience. By clicking “I agree” you accept all cookies.
      </CookieConsent>
    </>
  );
};

export default CookieConsentWrapper;
