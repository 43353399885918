import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, Variants } from 'framer-motion';
import { Box } from '@chakra-ui/react';
import { SocialLink, SocialWrapper, Container } from './styles';
import { ISocialProps } from './interfaces';
import defaultLinks from './socialLinks';

const container: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const listItem: Variants = {
  hidden: {
    opacity: 0,
    x: 50,
  },
  show: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.6 },
  },
};

const Social: React.FC<ISocialProps> = ({
  socialLinks = defaultLinks,
  ...rest
}) => {
  return (
    <Box mb="40px" {...rest}>
      <Container initial="hidden" animate="show" variants={container}>
        {socialLinks.map((social) => (
          <motion.div variants={listItem} key={social.link}>
            <SocialWrapper key={social.link}>
              <SocialLink href={social.link} target="blank">
                <FontAwesomeIcon icon={social.icon} height="1em" />
              </SocialLink>
            </SocialWrapper>
          </motion.div>
        ))}
      </Container>
    </Box>
  );
};

export default Social;
