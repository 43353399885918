import createImageUrlBuilder from '@sanity/image-url';
import { createClient } from 'next-sanity';
import config from './sanity.config';

const { apiVersion, dataset, projectId } = config;
export const client = createClient({
  projectId,
  dataset,
  apiVersion,
  useCdn: false,
});

export const urlFor = (source: any) =>
  createImageUrlBuilder(config).image(source as any);

export const portableTextToPlainText = (blocks: any = []) => {
  return (
    blocks
      .map((block: any) => {
        // eslint-disable-next-line no-underscore-dangle
        if (block._type !== 'block' || !block.children) {
          return '';
        }
        return block.children.map((child: any) => child.text).join('');
      })
      // join the paragraphs leaving split by two linebreaks
      .join('\n\n')
  );
};

export const autoUrlFor = (source: any) =>
  createImageUrlBuilder(config)
    .image(source as any)
    .auto('format')
    .fit('max');

// Set up the live preview subsscription hook
// export const usePreviewSubscription = createPreviewSubscriptionHook(config);

// Set up Portable Text serialization
// export const PortableText = createPortableTextComponent({
//   ...config,
//   // Serializers passed to @sanity/block-content-to-react
//   // (https://github.com/sanity-io/block-content-to-react)
//   serializers: {},
// });

// Set up the client for fetching data in the getProps page functions
// export const sanityClient = createClient(config);
// // Set up a preview client with serverless authentication for drafts
// export const previewClient = createClient({
//   ...config,
//   useCdn: false,
//   token: process.env.SANITY_API_TOKEN,
// });

// Helper function for easily switching between normal client and preview client
// export const getClient = (usePreview: boolean) =>
//   usePreview ? previewClient : sanityClient;

// Helper function for using the current logged in user account
// export const useCurrentUser = createCurrentUserHook(config);
